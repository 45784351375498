import * as React from "react";
import {ASSETS} from "../common/images";

const OurExperts = () => {
    const expertListing = [
        {
          expertImg: ASSETS.ABOUT.Expert1,
          expertName: 'Daishy Goyal',
          expertPosition: 'CEO & Founder'
        },
        {
          expertImg: ASSETS.ABOUT.Expert2,
          expertName: 'Manoj Saini',
          expertPosition: 'CTO & Founder'
        },
        {
          expertImg: ASSETS.ABOUT.Expert3,
          expertName: 'Preeti Ghakhar',
          expertPosition: 'HR Manager'
        },
        {
          expertImg: ASSETS.ABOUT.Expert4,
          expertName: 'Rajat Mehta',
          expertPosition: 'JavaScript Developer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert5,
          expertName: 'Shubham Sasgohar',
          expertPosition: 'Mean Stack Developer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert6,
          expertName: 'Anurag Sharma',
          expertPosition: 'Mean Stack Developer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert7,
          expertName: 'Jagdish Chandra Sharma',
          expertPosition: 'Web Developer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert8,
          expertName: 'Gagan Bawa',
          expertPosition: 'Web Developer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert9,
          expertName: 'Deepanshi Arora',
          expertPosition: 'React Native Developer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert10,
          expertName: 'Champak Kumar',
          expertPosition: 'Business Manager'
        },
        {
          expertImg: ASSETS.ABOUT.Expert11,
          expertName: 'Gagandeep Singh',
          expertPosition: 'Web Designer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert12,
          expertName: 'Sudhir Bishnoi',
          expertPosition: 'Graphic Designer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert13,
          expertName: 'Abhishek Sharma',
          expertPosition: 'Web Developer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert14,
          expertName: 'Bhanu Handa',
          expertPosition: 'Web Developer'
        },
        {
          expertImg: ASSETS.ABOUT.Expert15,
          expertName: 'Chirag Saini',
          expertPosition: 'Web Developer'
        }
    ]
    return (
        <section className="our-experts-wrapper pt-5">
            <span className="our-expert-icon-1" />
            <img src={ASSETS.HOME.DotsBox1} alt="" className="our-expert-icon-2" />
            <img src={ASSETS.HOME.DotsBox1} alt="" className="our-expert-icon-3" />
            <span className="our-expert-icon-4" />
            <span className="our-expert-icon-5" />
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="experts-header-wrapper gb-common-left-sec-pad">
                            <h4 className="common-sec-sub-title text-uppercase mb-lg-5 mb-3 gb-color-red sub-title-common-left-divider">our experts</h4>
                            <h2 className="common-sec-title gb-color-black mb-lg-5 mb-3">Friendly Team</h2>
                            <p className="gb-description-small gb-color-black gb-bottom-mrt-60">Every day we communicate with each other, solve our clients problems and work hard to succeed their business.</p>
                        </div>
                    </div>
                </div>
                <div className="row">

                    {
                        expertListing.map( (row, index) => (
                            <div className="col-md-4 col-lg-3 col-6" key={'expert-'+index}>
                                <div className="gb-expert-common-col-wrapper mb-lg-5 mb-4">
                                    <img src={row.expertImg} className="expert-img" alt="expert img" />
                                    <div className="gb-expert-common-text-wrapper">
                                        <p className="expert-name">{row.expertName}</p>
                                        <p className="expert-position">{row.expertPosition}</p>
                                    </div>
                                </div>
                            </div>
                        ) )
                    }
                </div>
            </div>
        </section>
    )
}

export default OurExperts;
