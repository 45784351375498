import * as React from "react";
import {ASSETS} from "../common/images";

const HowOurDayStart = () => {
    return (
        <section className="how-our-day-start-wrapper mb-md-5 mb-3">
            <div className="container">
                <div className="row d-flex align-items-center flex-column-reverse flex-md-row">
                    <div className="col-md-6">
                        <div className="our-day-img-wrapper text-center pe-5">
                            <span className="white-circle" />
                            <img src={ASSETS.ABOUT.HowADayStart} alt="home banner icon" className="w-100" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="ps-5">
                            <h2 className="gb-color-black mb-lg-5 mb-3 common-sec-title">How Our Working <br/><span className="gb-color-red">Day Starts</span></h2>
                            <p className="gb-description-small gb-color-black mb-lg-5 mb-3">Every morning in our company begins with hot coffee and a stand-up. Discussion is an important thing in the development process.</p>
                            <p className="gb-description-small gb-color-black mb-lg-5 mb-3">Thus new ideas and ingenious solutions are born. Then we get to work with renewed vigor and inspiration. We are never bored.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowOurDayStart;
