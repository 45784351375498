import * as React from "react";
import {ASSETS} from "../common/images";
import Slider from "react-slick";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const OurSpeciality = () => {

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "common-speciality-next-prev-btn speciality-prev-btn slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0}
            type="button"
        >
            &#8592;
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
        <button
            {...props}
            className={
                "common-speciality-next-prev-btn speciality-next-btn slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1}
            type="button"
        >
            &#8594;
        </button>
    );

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section className="our-speciality-wrapper pt-4 ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h2 className="common-sec-title gb-color-black mb-md-5 mb-4">What is the <br/><span className="gb-color-red">Speciality </span>Of Us?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <img src={ASSETS.HOME.DotsBox1} alt="" className="gb-speciality-fixed-icon-1" />
                        <div className="our-speciality">
                        <Slider {...settings}>
                            <div>
                                <div className="gb-common-speciality-sec">
                                    <img src={ASSETS.ABOUT.QuickResponse} className="gb-speciality-icon" alt="quick response" />
                                    <h4 className="title gb-color-black text-capitalize">Quick Response</h4>
                                    <p className="gb-description-small gb-color-grey">Lorem ipsum dolor sit amet, vel accumsan liberavisse ex, ea nec concludaturque ndo. Verear</p>
                                </div>
                            </div>
                            <div>
                                <div className="gb-common-speciality-sec">
                                    <img src={ASSETS.ABOUT.GreatCommunication} className="gb-speciality-icon" alt="quick response" />
                                    <h4 className="title gb-color-black text-capitalize">Great Communication</h4>
                                    <p className="gb-description-small gb-color-grey">Lorem ipsum dolor sit amet, vel accumsan liberavisse ex, ea nec concludaturque ndo. Verear</p>
                                </div>
                            </div>
                            <div>
                                <div className="gb-common-speciality-sec">
                                    <img src={ASSETS.ABOUT.CustomerSatisfaction} className="gb-speciality-icon" alt="quick response" />
                                    <h4 className="title gb-color-black text-capitalize">customer satisfaction</h4>
                                    <p className="gb-description-small gb-color-grey">Lorem ipsum dolor sit amet, vel accumsan liberavisse ex, ea nec concludaturque ndo. Verear</p>
                                </div>
                            </div>
                            <div>
                                <div className="gb-common-speciality-sec">
                                    <img src={ASSETS.ABOUT.QuickResponse} className="gb-speciality-icon" alt="quick response" />
                                    <h4 className="title gb-color-black text-capitalize">Quick Response</h4>
                                    <p className="gb-description-small gb-color-grey">Lorem ipsum dolor sit amet, vel accumsan liberavisse ex, ea nec concludaturque ndo. Verear</p>
                                </div>
                            </div>
                            <div>
                                <div className="gb-common-speciality-sec">
                                    <img src={ASSETS.ABOUT.GreatCommunication} className="gb-speciality-icon" alt="quick response" />
                                    <h4 className="title gb-color-black text-capitalize">Great Communication</h4>
                                    <p className="gb-description-small gb-color-grey">Lorem ipsum dolor sit amet, vel accumsan liberavisse ex, ea nec concludaturque ndo. Verear</p>
                                </div>
                            </div>
                        </Slider>
                        </div>
                    </div>
                </div>
        </section>
    )
}
export default OurSpeciality;
