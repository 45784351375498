import * as React from "react";
import {ASSETS} from "../common/images";
import Breadcrumbs from "../common/breadcrumbs";

const Banner = () => {
    return (
 <section className="gb-banner-wrapper gb-about-banner">
            <span className="common-header-shape-1" />
            <img src={ASSETS.HOME.CommonHeaderShape2} className="common-header-shape-2" alt="common-header-shape-2" />
            <span className="common-header-shape-3" />
            <img src={ASSETS.HOME.CommonHeaderShape4} className="common-header-shape-4" alt="common-header-shape-4" />
            <span className="common-header-shape-5" />
            <span className="common-header-shape-6" />
            <span className="common-header-shape-7" />
            <span className="common-header-shape-8" />
            <span className="common-header-shape-9" />
     <img src={ASSETS.HOME.CommonHeaderShape6} className="common-header-shape-10" alt="common-header-shape-10" />
            <div className="container">
                <Breadcrumbs pageTitle={'About us'} currentPage={'About'} />
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <div className="banner-title-wrapper">
                            <h2 className="title gb-color-black mb-lg-5 mb-3">Wa Are Creative &<br/><span className="gb-color-red">Strong Team</span></h2>
                            <p className="gb-description-small gb-color-black mb-lg-4 mb-3">Our creative and professional agency has been developing products for 4 years. We are special.</p>
                            <p className="gb-description-small gb-color-black mb-lg-4 mb-3">We’ve got a lot of awards for our work and develop applications that became popular in the world. We try not to miss important details in each area.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="banner-img text-center">
                            <img src={ASSETS.ABOUT.AboutBanner} alt="home banner icon" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner;
