import * as React from "react";
import Layout from "../components/layout";
import Banner from "../components/about/banner";
import {ASSETS} from "../components/common/images";
import HowOurDayStart from "../components/about/how-our-day-start";
import OurSpeciality from "../components/about/our-speciality";
import OurExperts from "../components/about/our-experts";

const AboutPage = () => {
  return (
      <Layout>
          <Banner />
          <HowOurDayStart />
          <OurSpeciality />
          <OurExperts />
      </Layout>
  )
}
export default AboutPage;
